<template>
  <div>
    <h3>30 July 2010 (0.189.3)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- The tutorial for Epsilon’s new weapon mission should now function correctly.
      <br>- The tutorial arrow for speedchat/salute during Beck’s mission no longer gets stuck on screen.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The Dragon Instance lobby no longer uses the Survival Instance lobby screen.
      <br>- You can now complete “The System is Down” repeatable mission.
      <br>- Goat pets no longer have placeholder names.
    </p>
    <p>
      <u>LEGO Club</u>
      <br>- The LEGO Club popup will now display the correct URL.
    </p>
    <p>
      <u>Property</u>
      <br>- All Medium Property launchpads have been removed.
    </p>
    <p>
      <u>General</u>
      <br>- Falling through worlds on lower-spec computers should be greatly reduced.
    </p>
    <h3>29 July 2010 (0.189.2)</h3>
    <p>
      <u>Front End</u>
      <br>- You will now only get the error message that your account is locked, when this is in fact the case.
    </p>
    <p>
      <u>Property</u>
      <br>- Exceeding the property plaque’s character limit will no longer bypass word filtering.
    </p>
    <p>
      <u>General</u>
      <br>- You should no longer encounter invisible walls.
    </p>
    <h3>27 July 2010 (0.189.1)</h3>
    <p>
      <u>Front End</u>
      <br>- New Minifigures will now be able to load into the Venture Explorer the first time they attempt to enter the game.
    </p>
    <p>
      <u>Missions</u>
      <br>- The user interface for missions now appears properly.
    </p>
    <h3>26 July 2010 (0.189.0)</h3>
    <p>
      <u>Log In</u>
      <br>- You will see a unique message that your account is temporarily locked if you have tried logging in 3 times with invalid passwords.
    </p>
    <p>
      <u>Passport</u>
      <br>- Missions have been sorted properly in the passport.
      <br>- Armor Power-Ups should now be tracked properly in your passport stats.
      <br>- The amounts for Total Distance Traveled and Total Imagination are now accurate.
      <br>- The sound for completing achievements has been changed.
    </p>
    <p>
      <u>User Interface</u>
      <br>- If you are in the middle of changing zones while being invited to join a group, you will receive the invite when you are zoned in.
      <br>- Loading screens now appear correctly.
    </p>
    <p>
      <u>General</u>
      <br>- Loot will not be deleted when falling from sharks or other objects placed at large heights.
      <br>- You will no longer lose bricks after a client crash.
      <br>- Characters should not get “out of sync” when the server crashes at certain important times in their lives.
      <br>- The mission giver light will no longer disappear inappropriately.
      <br>- You will no longer see missions you cannot get.
      <br>- There is a new fountain reward model.
      <br>- There is a new soundtrack for rocket animations.
    </p>
    <p>
      <u>Racing</u>
      <br>- You will no longer be able to start racing after the 3-2-1 countdown.
      <br>- Chat while racing has been improved.
      <br>- Music cues have been added to both race tracks.
      <br>- You will load into a race track with the car you double click on.
    </p>
    <p>
      <u>Property</u>
      <br>- You will no longer get stuck when opening property manager quickly after closing it.
      <br>- You now have to complete the property tutorial missions before others can get to your property.
      <br>- All property guards teleport away after their last mission is completed.
    </p>
    <p>
      <u>Build</u>
      <br>- The part connections for the rocket and car icons have been improved.
      <br>- Anytime you enter a build, your weapons are now unequipped. They are reequipped when you leave the build.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Interrupting a looping behavior with state changing will no longer cause the behavior to wait for an unnecessary length of time before restarting.
      <br>- The restart block will now cause the model to fade out instead of playing the smash animation.
      <br>- Static models no longer rotate upon collision with other objects.
      <br>- Models will no longer float away when properties are paused of after low-speed collisions.
    </p>
    <p>
      <u>Trade</u>
      <br>- The buyback inventory size has changed from 20 to 24.
      <br>- Models with behaviors attached to them can now be traded and retain their behaviors.
      <br>- New vendor windows with new colors, new button, and new “locked” item text.
    </p>
    <p>
      <u>Pets</u>
      <br>- You will no longer get stuck before the naming prompt the first time you tame a pet.
      <br>- Pets will go to their dig locations more reliably now.
      <br>- The warthog is now tameable.
    </p>
    <p>
      <u>Friends/Best Friends</u>
      <br>- The Best Friends system is up and running again.
      <br>- Friend requests are no longer missed if received while you are changing zones.
      <br>- Best Friends should be able to free chat again.
      <br>- You can now have up to 50 Friends/Best Friends.
      <br>- Friend requests will no longer repeat.
    </p>
    <p>
      <u>Skills</u>
      <br>- A celebration emote has been added.
      <br>- Transition times have been changed to make jumping and double jumping a little better with the run transition.
      <br>- Many updates to the visual effects for various attacks.
    </p>
    <p>
      <u>Chat</u>
      <br>- Typed text will once again turn red, if it is not on the whitelist.
      <br>- The chat window is now slightly smaller, but the amount of viewable text is greater due to margin changes.
    </p>
    <p>
      <u>Backpack</u>
      <br>- All inventory item deletion dialogs now repeat so you can confirm before losing anything.
      <br>- Faction tokens will now stack if your inventory is full.
      <br>- Backpack will no longer get stuck off screen when reducing screen size.
    </p>
    <p>
      <u>Venture Explorer</u>
      <br>- Rocket launch music plays correctly.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- The Survival Instance has new music.
      <br>- Music has been added to the monument race.
      <br>- Added butterfly. Bees and lady bugs to the launch pad area.
      <br>- Swapped showcase and sentinel encampment vendor locations. -Added a cinematic to sentinel engineer lumber mission.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Launching multiple rockets to Pet Cove will no longer cause rockets to become temporarily stuck on-screen.
      <br>- There are now rocks where there were previously invisible walls blocking players from walking behind the buildings in Brick Annex.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Camera showing hanging sharks has been adjusted to match new position of hanging sharks.
      <br>- Moving platform no longer becomes transparent when walked on.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The Dragon fight is now an Instance, accessible from the refinery via a statue.
      <br>- Gongs have been moved into their proper places.
      <br>- The ramp leading up to the left side of the middle dragon should no longer cause you to get stuck behind the wall.
      <br>- Dragon loot has been updated.
      <br>- Quick-Build platform leading to Numb Chuck is no longer slow building.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- The treasure chest dig next to the terrier pets has been removed.
    </p>
    <p>
      <u>Starbase 3001</u>
      <br>- Wall textures are now properly aligned.
      <br>- Added models for showcase display in Starbase 3001.
    </p>
    <h3>30 July 2010 (0.188.11d) </h3>
    <p>
      <u>Login</u>
      <br>- Fixed issue where players were getting stuck at "Submit Username" step of login.
    </p>
    <h3>12 July 2010 (0.188.0) </h3>
    <p>
      <u>Front End</u>
      <br>- Intro cinematic has been revised.
    </p>
    <p>
      <u>Venture Explorer</u>
      <br>- Skylane’s idle is improved.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Visual and physics improvements to the battle area.
      <br>- Closed holes in flower beds.
      <br>- Rocket pads have been aligned to terrain and effects.
      <br>- Battlefield beacon Quick-Builds now work correctly.
      <br>- Epsilon’s jet pack has been fixed.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- You should no longer become stuck completing Mardolf’s modular build mission to receive the Assembly faction stamp.
      <br>- The walls near the LEGO Club vendor are now as solid as good walls ought to be.
      <br>- Johnny Thunder’s images have been updated to match his new look.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- The floating pet dig treasure chest has been removed.
      <br>- Physics improvements have been made near Cat and Terrier areas.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Pirate Trench Chests will not double spawn when smashed.
      <br>- Names of in-game characters have been updated.
      <br>- Collision for the pirate arch wall in Gnarled Forest now behaves as expected.
      <br>- Maelstrom crates will smash correctly.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- You can no longer fall through the rocks near the entrance gate to Forbidden Valley.
      <br>- A new gate has been created for the Dragon Scene.
      <br>- The Dragon vs. Troll fight animations have been updated.
      <br>- Candles no longer disappear.
      <br>- Dragon animations have changed.
    </p>
    <p>
      <u>LEGO Club</u>
      <br>- The physics for the LEGO Club area have been improved.
    </p>
    <p>
      <u>Build Modes and Behaviors</u>
      <br>- Private properties are back!
      <br>- Your last brick mode session is now automatically recovered and placed on property when you return after a disconnect.
      <br>- The rotation action has been fixed.
      <br>- Removing certain behaviors no longer makes you disconnect.
      <br>- Building models with multi-part bricks now work correctly and all parts fly out when smashed.
      <br>- Your Thinking Hat is no longer unequipped by hitting the escape key after a modular build.
      <br>- Pirate models work in brick mode now.
      <br>- More bricks have been added for use in Brick Mode!
    </p>
    <p>
      <u>Friends</u>
      <br>- Best Friends is here!
      <br>- Best friends can send personal messages to each other with no chat restrictions.
      <br>- Best friends can go to each other’s property regardless of moderation state.
    </p>
    <p>
      <u>Pets</u>
      <br>- The action bar has been updated, so that Imagination bar appears in the pet taming mini-games.
      <br>- Effects for the pet bouncers have been updated.
    </p>
    <h3>11 July 2010 (0.187.0)</h3>
    <p>
      <u>Front End</u>
      <br>- The new character intro video is in!
      <br>- Bright yellow is no longer available as a shirt color in character create.
      <br>- The character delete password confirmation box is now case-sensitive.
      <br>- Gorilla and cannon effects were updated.
    </p>
    <p>
      <u>Pets</u>
      <br>- The bunny should now be fully tamable.
      <br>- The Imagination you use to tame pets will now be subtracted correctly.
      <br>- You will no longer risk losing your pet, when you disconnect before your pet’s name has been approved.
      <br>- Pets now go to their Pet Bouncer switches more consistently.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Monument lasers now cause an upward knockback which makes you trip the trigger again, if you stand still on the laser.
      <br>- Names of characters in-game have been updated.
      <br>- Brick drop rate in Avant Gardens has been doubled.
      <br>- Token drop rate from baby spiders has been decreased.
      <br>- Sliding backwards while using the launchpad to Nimbus Station is no longer an issue.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Crates near Pirate Mechanic have been moved.
      <br>- The hanging sharks now respawn faster.
      <br>- Names of characters in-game have been updated.
      <br>- Token drop rate on pirates in trench area has been increased slightly.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Names of characters in-game have been updated.
      <br>- The token drop rate from cavalry and ronin has been decreased slightly.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Names of characters in-game have been updated.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- Names of characters in-game have been updated.
    </p>
    <p>
      <u>Racing</u>
      <br>- You now respawn appropriately.
      <br>- Message at Gnarled Forest race track has been updated. Note that you do not have to complete the entire Nimbus Station race mission in order to race at Gnarled Forest – all you need is Velocity Lane’s first two missions under your belt and a car!
      <br>- Vehicles cannot collide after having crossed the finish line.
    </p>
    <p>
      <u>User Interface</u>
      <br>- The friends list now also works while you interact with vendors and while you are in Modular Build mode.
      <br>- The action bar has been updated.
      <br>- News screen will close when you get hit.
      <br>- You get stunned when viewing News so you can’t create a blocker by interacting.
      <br>- Trades can no longer be accepted or held open when going out of range.
      <br>- Tutorials arrows should now behave better.
    </p>
    <p>
      <u>Faction Kits and Items</u>
      <br>- Samurai and knight triggers should now activate when you wear 4 pieces of the same rank and run out of armor.
      <br>- Daredevil “Danger Sense” bonus skill now shows all normal minibosses in the standard game world.
      <br>- Various guns and ranged weapons have had descriptions added.
      <br>- The 3 ninja gis (karate outfits) now have a “left hand” skill that throws a spread of 3 projectiles.
      <br>- Hiccup tablets now grant 3 Imagination instead of 1 and have a 10 second cooldown.
      <br>- Lowered prices on Specialty Kit items (coins and tokens).
    </p>
    <p>
      <u>Movement</u>
      <br>- Moving platforms have a smooth motion once again.
      <br>- Smashable bricks should now appear to have a smoother motion.
    </p>
    <p>
      <u>Mail</u>
      <br>- You now properly get mail that contains activity reward items that didn’t fit in your backpack.
    </p>
    <p>
      <u>Models</u>
      <br>- The Ninja Fortress model set is now in the game.
      <br>- Many various LUP models are now in the game.
    </p>
    <p>
      <u>Achievements</u>
      <br>- The Twenty-Time Champion achievement in racing now awards the black Thrust Bucket car package.
      <br>- The pet menu no longer overlaps with the armor bar.
    </p>
    <p>
      <u>Chat</u>
      <br>- The word filter cannot be bypassed by whispering.
      <br>- The word suggest list is no longer focused by default. Pressing up or mousing over one of the entries will grant it focus.
      <br>- Speedchat is no longer accessible from shooting gallery or racing.
      <br>- It is now possible to click on a player’s name in chat and bring up the player context menu.
    </p>
    <p>
      <u>Mail</u>
      <br>- Word suggest and word filtering has been fixed when composing mail messages.
    </p>
    <p>
      <u>Skills and Items</u>
      <br>- Full group of enemies will be knocked back when the skill demands it.
      <br>- Loot and coins will find better terrain positions to land on.
      <br>- Action bar removes tooltips when skills are removed.
      <br>- Several skills have been updated.
      <br>- All speed boost skills gives you 50% speed bonus.
      <br>- The armor repair charge up for the Assembly faction kit weapons now charges faster and repairs more armor.
    </p>
    <p>
      <u>Audio</u>
      <br>- Audio mutes during client shutdown.
      <br>- Music will now play when using certain rocket launch pads.
      <br>- Music will now play when completing the rocket builds on the Venture Explorer.
      <br>- New music for Pirate Strombies and Captains, Ronin and Mounted, Emotes, Venture Explorer leaving cinematics, Avant Gardens arrival cinematic, Gnarled Forest Gorilla, Pets and Smashables.
    </p>
    <p>
      <u>Inventory</u>
      <br>- Modular build rockets and cars now show unique roll-over icons for inventory.
      <br>- You are now able to spend your last coin.
      <br>- All placeholder icons in your backpack have been replaced with unique ones.
      <br>- Paperdoll updates correctly.
      <br>- Only consumable items can be dropped into the consumable slot.
    </p>
    <p>
      <u>Passport</u>
      <br>- Hovering the mouse over an item in the rewards section of a Mission or Achievement will now show the full item details.
      <br>- There are now 9 more “player statistics” on the first page of the passport related to racing.
      <br>- Racing Imagination pickups now register as player statistics.
    </p>
    <p>
      <u>News Screen</u>
      <br>- The news screen will now show the proper achievement information.
    </p>
    <p>
      <u>Trade</u>
      <br>- You will no longer lose items after trading.
    </p>
    <p>
      <u>Vendors</u>
      <br>- Consumable stack size will now update properly when selling to a vendor.
    </p>
    <h3>10 July 2010 (0.186.6)</h3>
    <p>
      <u>Pets</u>
      <br>- If a pet name fails moderation, you will now see the rename pet dialog.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The quickbuilds in the concert area will no longer create instruments that last forever…
    </p>
    <p>
      <u>Best Friends</u>
      <br>- The Best Friends button has been disabled.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The dragon will now be fully visible on mid-to-min spec machines.
      <br>- The windy canyons now work.
      <br>- You can no longer walk through the Ninja Gates without a Ninja Hood. Foot Race
      <br>- The Foot Race no longer crashes.
      <br>- The text has been updated.
    </p>
    <p>
      <u>Racing</u>
      <br>- The minimum number of racers has been set to four.
    </p>
  </div>
</template>
